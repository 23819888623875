<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_room_fare')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-snackbar v-model="snackbar" color="error" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-select v-model="editedData.roomCategoryID"
                              :items="roomCategory"
                              dense
                              outlined
                              :label="$t('room_category')"
                              @change="getRoomType"
                              item-value="value"
                              item-text="text"></v-select>

                    <v-select v-model="editedData.roomTypeID"
                              :items="roomType"
                              dense
                              outlined
                              :label="$t('room_type')"
                              item-value="id"
                              item-text="value"></v-select>

                    <v-text-field v-model="editedData.Fare"
                                  :error-messages="FareErrors"
                                  :label="$t('fare')"
                                  required
                                  dense
                              outlined
                                  @input="$v.editedData.Fare.$touch()"
                                  @blur="$v.editedData.Fare.$touch()"></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success"],
        computed: {
            FareErrors() {
                const errors = [];
                if (!this.$v.editedData.Fare.$dirty) return errors;
                !this.$v.editedData.Fare.required &&
                errors.push("Fare is required.");
                return errors;
            },
            roomTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.roomTypeID.$dirty) return errors;
                !this.$v.editedData.roomTypeID.required &&
                errors.push("Room Type is required.");
                return errors;
            },
            roomCategoryErrors() {
                const errors = [];
                if (!this.$v.editedData.roomCategoryID.$dirty) return errors;
                !this.$v.editedData.roomCategoryID.required &&
                errors.push("Room Category is required.");
                return errors;
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                returnData: [],
                roomType: [],
                roomCategory: [],
                province: [],
                show: false,
                updated: false,
                editedData: {
                    Fare: "",
                    roomTypeID: "",
                    roomCategoryID: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                roomTypeID: {required},
                roomCategoryID: {required},
                Fare: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                axios.get('Hotel/DDLHotelRoomCategoryListAsync').then((response) => {
                    this.roomCategory = response.data;
                })
                this.updated = false;
            },
            getRoomType(val) {
                axios.get('Hotel/DDLHotelRoomTypeListByRoomCategoryIDAsync/' + val).then(response => {
                    this.roomType = response.data
                }).catch(err => {
                    console.log(err)
                });
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        roomTypeID: parseInt(this.editedData.roomTypeID),
                        roomCategoryID: parseInt(this.editedData.roomCategoryID),
                        Fare: parseFloat(this.editedData.Fare)
                    };
                    axios.post("Hotel/InsertIntoHotelRoomFareAsync", param).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Fare Created Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        } else {
                            this.snackbar = true
                            this.callbackResponse.message = response.data.errors[0];
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>