<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      color="success"
      :timeout="callbackResponse.timeout"
      top
    >
      {{ callbackResponse.message }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogEditRoom" max-width="500px">
      <editRoomForm
        v-if="dialogEditRoom"
        :editData="editedDataService.hotelCustomerServiceID"
        :success="success"
        @formResponseRoom="onResponseRoom"
      />
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <deleteListData
        :deleteFormData="deleteFormData"
        @formResponse="onResponseDelete"
      ></deleteListData>
    </v-dialog>

    <v-dialog v-model="dialogCreateRoom" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          dark
          class="ma-4 float-right"
          v-on="on"
          :success="success"
          >Add Services</v-btn
        >
      </template>
      <createRoomForm :success="success" @formResponseRoom="onResponseRoom" />
    </v-dialog>
    <v-container fluid>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :pagination-options="{ enabled: true }"
        :rows="roomRows"
        :columns="columnsRoom"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click="editItemRoom(props.row)"
              >edit</v-icon
            >
            <v-icon small @click="deleteItemRoom(props.row)">delete</v-icon>
          </span>
        </template>
      </vue-good-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import createRoomForm from "./CreateService";
import editRoomForm from "./EditService";

import deleteListData from "@/components/deleteModal";
import { parseJwt } from "@/mixins/parseJwt";

export default {
  name: "List",
  mixins: [parseJwt],
  watch: {
    dialog(val) {
      val || this.close();
    },
    success: {
      handler: function(val, oldVal) {
        console.log(val, oldVal);
        this.loadItems();
      },
      deep: true,
    },
  },
  components: {
    createRoomForm,
    editRoomForm,
    deleteListData,
  },
  data() {
    return {
      callbackResponse: {
        timeout: 6000,
      },
      snackbar: false,
      success: false,
      dialogCreateRoom: false,
      dialogEditRoom: false,
      dialogDelete: false,
      panel: [0],
      deleteFormData: {},
      popout: true,
      isLoading: false,
      columnsRoom: [
        {
          label: "Customer Service ID",
          field: "hotelCustomerServiceID",
        },
        {
          label: "Customer Service",
          field: "hotelCustomerService",
        },
        {
          label: "Service Description",
          field: "hotelServiceDescription",
        },
        {
          label: "Service Rate",
          field: "hotelServiceRate",
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
      roomRows: [],
      totalRecords: 0,
      serverParamsRoom: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "roomNumber",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },

      user: [],
      editedIndex: -1,
      editedDataService: {},
    };
  },

  mounted() {
    this.loadItemsRoom();
    this.filterData();
  },
  destroyed() {
    console.log(this); // There's practically nothing here!
  },
  methods: {
    onResponseRoom(data) {
      if (data.status) {
        this.dialogCreateRoom = false;
        this.dialogEditRoom = false;
        this.callbackResponse.message = data.message;
        this.$emit("formResponseRoom", data);
        this.loadItemsRoom();
      }
    },
    onResponseDelete(data) {
      console.log("ListRoomDelete", data);
      if (data) {
        this.$emit("formResponseRoomDelete", data);
        this.dialogDelete = false;
        this.loadItemsRoom();
      } else {
        this.dialogDelete = false;
      }
    },
    editItemRoom(props) {
      this.editedDataService = props;
      this.dialogEditRoom = true;
      // this.dialogEditFare = true;
    },

    deleteItemRoom(props) {
      console.log(props);
      this.dialogDelete = true;
      this.deleteFormData.url =
        "Hotel/DeleteHotelRoomAsync/" + props.hotelCustomerServiceID;
      this.loadItemsRoom();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    searchInput() {
      this.loadItems();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async filterData() {},
    // load items is what brings back the rows from server
    async loadItemsRoom() {
      console.log(this.tokenData);
      // eslint-disable-next-line no-console
      let param = {
        PageSize: this.serverParamsRoom.perPage,
        PageNo: this.serverParamsRoom.page,
        OrderType: this.serverParamsRoom.sort[0].type,
        OrderBy: this.serverParamsRoom.sort[0].field,
      };
      try {
        const response = await axios.post(
          "Hotel/GetHotelCustomerServicesListAsync",
          param
        );
        this.roomRows = response.data;
        // this.totalRecords = response.data.totalCount;
      } catch (e) {
        if (e.response.data.State == "AccessTokenExpired") {
          this.apiResponse(e);
          this.loadItems();
        }
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item-group {
  background: #d2e3ff73;
}

.v-expansion-panel-header {
  background: #d2e3ff73;
}

button {
  z-index: 1;
}
</style>
