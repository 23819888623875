<template>
    <div>
        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="dialogEdit" max-width="500px">
            <editRoomForm
                    v-if="dialogEdit"
                    :editData="editedDataRoom"
                    :success="success"
                    @formResponse="onResponse"
            />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponseDelete"></deleteListData>
        </v-dialog>
        <v-dialog v-model="dialogCreate" max-width="500px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="ma-4 float-right" v-on="on" :success="success">{{$t('add_room')}}</v-btn>
            </template>
            <createRoomForm :success="success" @formResponse="onResponse"/>
        </v-dialog>
        <v-container fluid>
            <vue-good-table mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true, perPageDropdown: [20, 50, 100,200]}"
                            :rows="roomRows"
                            :columns="columnsRoom">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click="editItemRoom(props.row)">edit</v-icon>
                        <v-icon small @click="deleteItemRoom(props.row)">delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
        </v-container>
    </div>
</template>

<script>
    import axios from "axios";
    import createRoomForm from "./createRoom";
    import editRoomForm from "./editRoom";

    import deleteListData from "@/components/deleteModal";
    import {parseJwt} from "@/mixins/parseJwt";

    export default {
        name: "List",
        mixins: [parseJwt],
        components: {
            createRoomForm,
            editRoomForm,
            deleteListData
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columnsRoom: [
                    {
                        label: 'table.room_number',
                        field: "roomNumber"
                    },
                    // {
                    //   label: "Category",
                    //   field: "roomCategory"
                    // },
                                        {
                        label: 'room_id',
                        field:"roomID"
                    },
                    {
                        label: 'type',
                        field: "roomType"
                    },
                    {
                        label: 'fare',
                        field: "fare"
                    },
                    {
                        label: 'actions',
                        field: "actions",
                        sortable: false
                    }
                ],
                roomRows: [],
                totalRecords: 0,
                serverParamsRoom: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "roomNumber",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },

                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItemsRoom();
            this.filterData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                console.log("room", data)
                if (data) {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false;
                    this.callbackResponse.message = data.message;
                    this.$emit("formResponse", data);
                    this.loadItemsRoom();
                }
            },
            onResponseDelete(data) {
                console.log("ListRoomDelete", data)
                if (data) {
                    this.$emit("formResponse", data);
                    this.dialogDelete = false;
                    this.loadItemsRoom();
                } else {
                    this.dialogDelete = false;
                }
            },
            editItemRoom(props) {
                this.editedDataRoom = props;
                this.dialogEdit = true;
                // this.dialogEditFare = true;
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Hotel/DeleteHotelRoomAsync/" + props.roomID;
                this.loadItemsRoom();
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParamsRoom = Object.assign({}, this.serverParamsRoom, newProps);
            },

            searchInput() {
                this.loadItemsRoom();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItemsRoom();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItemsRoom();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItemsRoom();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItemsRoom();
            },
            async onDownload() {

                await axios({
                    url: 'Hotel/Download', //your url
                    method: 'POST',
                    responseType: 'blob'
                    // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
                //.post("Hotel/Download");

                //       var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                //       console.log(response)
                //       var blob = new Blob([response.data], { type: contentType });
                //       console.log(blob);
                //var link=document.createElement('a');
                //             link.href=window.URL.createObjectURL(blob);
                //             link.download="Report.xlsx";
                //       link.click();

                //ExportListToExcel
            },

            async filterData() {
            },
            // load items is what brings back the rows from server
            loadItemsRoom() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParamsRoom.perPage,
                    PageNo: this.serverParamsRoom.page,
                    OrderType: this.serverParamsRoom.sort[0].type,
                    OrderBy: this.serverParamsRoom.sort[0].field
                };
                try {
                    axios.post("Hotel/GetHotelRoomListAsync", param).then(response => {
                        this.roomRows = response.data.data;
                        this.totalRecords = response.data.totalCount

                    });
                    // this.totalRecords = response.data.totalCount;
                } catch (e) {
                    if (e.response.data.State == "AccessTokenExpired") {
                        this.apiResponse(e);
                        this.loadItemsRoom();
                    }
                }
                return;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>
