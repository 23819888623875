<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_room')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-snackbar v-model="snackbar" color="error" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-card-text> 
            <v-container>
                <form>
                    <v-select
                            v-model="editedData.fareType"
                            :items="fareType"
                              dense
                              outlined
                            :label="$t('fare_type')"
                            item-value="id"
                            item-text="value"
                    ></v-select>
                    <v-text-field
                            v-model="editedData.roomName"
                             :label="$t('room_name')"
                                  dense
                              outlined
                            :error-messages="roomNameErrors"
                            required
                            @input="$v.editedData.roomName.$touch()"
                            @blur="$v.editedData.roomName.$touch()"
                    ></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success"],
        computed: {
            fareTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.fareType.$dirty) return errors;
                !this.$v.editedData.fareType.required &&
                errors.push("Room Type is required.");
                return errors;
            },
            roomNameErrors() {
                const errors = [];
                if (!this.$v.editedData.roomName.$dirty) return errors;
                !this.$v.editedData.roomName.required &&
                errors.push("Room Name is required.");
                return errors;
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                returnData: [],
                fareType: [],
                show: false,
                updated: false,
                snackbar: false,
                editedData: {
                    fareType: "",
                    roomName: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                fareType: {required},
                roomName: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                axios.get('Hotel/DDLRoomFareListAsync', {}).then(response => {
                    this.fareType = response.data
                });
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        FareID: parseInt(this.editedData.fareType),
                        Status: true,
                        RoomNumber: this.editedData.roomName,

                    };
                    axios.post("Hotel/InsertIntoHotelRoomAsync", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Room Created Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                            this.$v.$reset();

                        } else {
                            this.snackbar = true
                            this.callbackResponse.message = response.data.errors[0];
                        }

                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>