<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_room_fare')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form> 
                    <v-select
                            v-model="editedData.roomTypeID"
                            :items="roomType"
                            :label="$t('room_type')"
                              dense
                              outlined
                            item-value="value"
                            item-text="text"
                    ></v-select>
                    <v-select
                            v-model="editedData.roomCategoryID"
                            :items="roomCategory"
                            :label="$t('room_category')"
                            item-value="value"
                              dense
                              outlined
                            item-text="text"
                    ></v-select>
                    <v-text-field
                            v-model="editedData.fare"
                            :error-messages="FareErrors"
                            :label="$t('fare')"
                            required
                                  dense
                              outlined
                            @input="$v.editedData.Fare.$touch()"
                            @blur="$v.editedData.Fare.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success", "editData"],
        computed: {
            FareErrors() {
                const errors = [];
                if (!this.$v.editedData.fare.$dirty) return errors;
                !this.$v.editedData.fare.required &&
                errors.push("Fare is required.");
                return errors;
            }
        },
        data() {
            return {
                returnData: [],
                roomType: [],
                roomCategory: [],
                province: [],
                show: false,
                updated: false,
                editedData: {
                    fare: 0,
                    hotelRoomTypeID: "",
                    hotelRoomCategoryID: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                roomTypeID: {required},
                roomCategoryID: {required},
                fare: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                axios.get('Hotel/GetHotelRoomFareByIDAsync/' + this.editData.fareID).then(response => {
                    this.editedData = response.data
                })

                axios.get('Hotel/DDLHotelRoomTypeListAsync').then((response) => {
                    this.roomType = response.data;
                })
                axios.get('Hotel/DDLHotelRoomCategoryListAsync').then((response) => {
                    this.roomCategory = response.data;
                })
                this.updated = false;

            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        fareID: this.editedData.fareID,
                        RoomTypeID: this.editedData.roomTypeID,
                        RoomCategoryID: this.editedData.roomCategoryID,
                        Fare: parseFloat(this.editedData.fare)
                    };
                    axios.post("Hotel/UpdateHotelRoomFareAsync", param).then(response => {
                        if (response.data.success) {
                            this.servicesData.data = response.data
                            this.servicesData.message = "Fare Updated Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                            this.$v.$reset();
                        }

                    });
                    this.submitStatus = "PENDING";
                    setTimeout(() => {
                        this.submitStatus = "OK";
                    }, 500);
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>