<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_service')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text> 
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.HotelCustomerService"
                            :label="$t('customer_service')"
                                   dense
                                  outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.HotelServiceRate"
                            :label="$t('service_rate')"
                                   dense
                                  outlined
                            :error-messages="HotelServiceRateErrors"
                            required
                            @input="$v.editedData.HotelServiceRate.$touch()"
                            @blur="$v.editedData.HotelServiceRate.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.HotelServiceDescription"
                                   dense
                                  outlined
                            :label="$t('service_description')"
                    ></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success"],
        computed: {
            HotelCustomerServiceErrors() {
                const errors = [];
                if (!this.$v.editedData.HotelCustomerService.$dirty) return errors;
                !this.$v.editedData.HotelCustomerService.required &&
                errors.push("Package Name is required.");
                return errors;
            },
            HotelServiceRateErrors() {
                const errors = [];
                if (!this.$v.editedData.HotelServiceRate.$dirty) return errors;
                !this.$v.editedData.HotelServiceRate.required &&
                errors.push("Package Type is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated && val) {
                        this.updated = false;
                        this.$emit("formResponseRoom", this.returnData);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {
                    HotelCustomerService: "",
                    HotelServiceRate: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                HotelCustomerService: {required},
                HotelServiceRate: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {

                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        HotelCustomerService: this.editedData.HotelCustomerService,
                        HotelServiceRate: parseInt(this.editedData.HotelServiceRate),
                        HotelServiceDescription: this.editedData.HotelServiceDescription
                    };
                    axios.post("Hotel/InsertIntoHotelCustomerServicesAsync", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Service Created Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>